.loading {
    display: flex;
    justify-content: center;
  }
  
  .loading--full-height {
    align-items: center;
    height: 100%;
  }

.loading .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color:  #081571;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 